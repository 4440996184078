import React from 'react';
import Table from 'react-bootstrap/Table';
import { useState, useEffect } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function List(props) {
    const { items, selectedItems, onSelectedItemsChange, itemStatus, buttonClicked, rolepackages, identificationUpdate } = props;
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [isDistributeGroupsSelected, setIsDistributeGroupsSelected] = useState(false);
    const [recentMessage, setRecentMessage] = useState(null);
    const [group, setGroup] = useState("");
    const [numberOfGroups, setNumberOfGroups] = useState(1);
    const [identificationExpected, setIdentificationExpected] = useState(false);

    //#region Update list when any message is sent (Show recently sent message/role for each participant in list and unselect any particpants)
    useEffect(() => {
        const userIds = items.map(item => item.idParticipant);

        const fetchPromises = userIds.map(userId => {
            const queryParams = `?userId=${encodeURIComponent(userId)}`;

            const fetchRecentMessage = fetch(`/fetch-recentMessage${queryParams}`)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else if (response.status === 404) {
                        return '';
                    } else {
                        throw new Error('An error occurred');
                    }
                })
                .catch(error => {
                    console.error(`Error fetching recent message for userId ${userId}:`, error);
                    return '';
                });

            const fetchIdentification = userId
                ? fetch(`/fetch-identification${queryParams}`)
                    .then(response => {
                        if (response.ok) {
                            return response.json();
                        } else if (response.status === 404) {
                            return '';
                        } else {
                            throw new Error('An error occurred');
                        }
                    })
                    .catch(error => {
                        console.error(`Error fetching identification for userId ${userId}:`, error);
                        return '';
                    })
                : Promise.resolve('');

            return Promise.all([fetchRecentMessage, fetchIdentification]);
        });

        Promise.all(fetchPromises)
            .then(data => {
                const recentMessages = data.map(([recentMessageData, identificationData], index) => ({
                    userId: userIds[index],
                    message: recentMessageData,
                    identification: identificationData.Characteristics,
                }));
                setRecentMessage(recentMessages);
            })
            .catch(error => {
                console.error('An error occurred:', error);
            });

        if (buttonClicked) {
            setIsAllSelected(false);
            onSelectedItemsChange([]);
        }
    }, [buttonClicked, items, identificationUpdate]);
    //#endregion

    //#region Handle selection of participants in list
    const onItemClick = (item) => {
        setIsAllSelected(false);

        onSelectedItemsChange((prevSelectedItems) =>
            prevSelectedItems.includes(item)
                ? prevSelectedItems.filter((selectedItem) => selectedItem !== item)
                : [...prevSelectedItems, item]
        );

    };
   
    const handleCheckAll = (event) => {
        const isChecked = event.target.checked;
        setIsAllSelected(isChecked);

        if (!isChecked) {
            onSelectedItemsChange([]);
        }
        else {
            onSelectedItemsChange(items);
        }
    };
    //#endregion

    //#region Distribute participants in list into groups
    const handleDistributeGroups = (event) => {
        const isCheckedGroup = event.target.checked;
        setIsDistributeGroupsSelected(isCheckedGroup);
        
        if (!isCheckedGroup || numberOfGroups <= 1) {
            setGroup("");
        }
        else {

            const divisionInteger = Math.floor(items.length / numberOfGroups);
            const divisionRemainder = items.length % numberOfGroups;
            let groups = [];
            let currentIndex = 0;

            for (let i = 0; i < numberOfGroups; i++) {
                const groupSize = divisionInteger + (i < divisionRemainder ? 1 : 0);
                const group = items.slice(currentIndex, currentIndex + groupSize);
                groups.push(group);
                currentIndex += groupSize;
            }

            setGroup(groups);
        }
    };

    useEffect(() => {
        if (isDistributeGroupsSelected) {
        const divisionInteger = Math.floor(items.length / numberOfGroups);
        const divisionRemainder = items.length % numberOfGroups;

        let groups = [];
        let currentIndex = 0;

        for (let i = 0; i < numberOfGroups; i++) {
            const groupSize = divisionInteger + (i < divisionRemainder ? 1 : 0);
            const group = items.slice(currentIndex, currentIndex + groupSize);
            groups.push(group);
            currentIndex += groupSize;
        }

           setGroup(groups);
        }
        if (numberOfGroups <= 1) {
            setIsDistributeGroupsSelected(false);
        }
    }, [numberOfGroups]);

    const handleNumberOfGroupsChange = (event) => {
        const value = parseInt(event.target.value);
        setNumberOfGroups(value);
    };
    
    const tooltipContent = numberOfGroups <= 1 ? "You must set a number greater than 1 to divide into groups" : "You can change the number of groups or remove groups at any time";
    //#endregion

    const getCircleColor = (item) => {
        return itemStatus[item.idParticipant] || 'red';
    };

    return (
        <div>
           
            <div className="form-check" style={{ textAlign: 'left' }}>
                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"  checked={isDistributeGroupsSelected} onChange={handleDistributeGroups} />
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="container-tooltip">{tooltipContent}</Tooltip>}
                >
                    <label htmlFor="flexCheckDefault" > Divide up all participants into <span><input className="number-of-groups"
                        type="number"
                        min={1}
                        max={items.length}
                        value={numberOfGroups}
                        onChange={handleNumberOfGroupsChange}
                    /></span> groups </label>
                </OverlayTrigger>
            </div>
           
            <div className="form-check" style={{ textAlign: 'left' }}>
                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={isAllSelected} onChange={handleCheckAll}  />
                <label className="form-check-label" htmlFor="flexCheckDefault" > Select all participants </label>
            </div>
            
            <div className="list-container">
                <Table striped hover className="rounded rounded-2 overflow-hidden ">
                <thead >
                    <tr style={{ background: '#446075 ', color: 'white' }}>
                        <th>#</th>
                        <th>Identification</th>
                        <th>Group</th>
                            <th>Latest sent message</th>
                            <th>Rolepackage</th>
                        <th>Message read</th>
                    </tr>
                </thead>

                  <tbody className="participant-list">
                    {items.map((item, index) => (
                        <tr 
                            style={{
                                background: selectedItems.includes(item) ? '#8fbc8f' : '#fff',
                                cursor: 'pointer',
                                fontWeight: 'bold'
                            }}
                            key={index}
                            onClick={() => onItemClick(item)}
                        >
                            <td style={{ color: selectedItems.includes(item) ? '#fff' : '#000' }}>{index + 1}</td>
                            <td style={{
                                color: selectedItems.includes(item) ? '#fff' : '#000', maxWidth: '35px', whiteSpace: "nowrap", overflow: 'hidden', fontWeight: '300', textOverflow: 'ellipsis'
                            }}>
                                {recentMessage && recentMessage.length > 0 && recentMessage.map((messageItem) => {
                                    if (messageItem.userId === item.idParticipant) {
                                        return messageItem.identification;
                                    }
                                    return null;
                                })}
                            </td>
                            {group.length > 0 ? (
                                group.map((groupItem, groupIndex) => {
                                    const matchingIndex = groupItem.findIndex((person) =>
                                        person.idParticipant === item.idParticipant
                                    );
                                    if (matchingIndex !== -1) {
                                        return (
                                            <td style={{ color: selectedItems.includes(item) ? '#fff' : '#000' }} key={groupIndex}>
                                                <span> Group {groupIndex + 1}</span>
                                            </td>
                                        );
                                    }
                                    return null;
                                })
                            ) : (
                                    <td></td>


                            )}
                            <td style={{ color: selectedItems.includes(item) ? '#fff' : '#000', fontWeight: '300' }}>
                                <div style={{ maxHeight: '70px', maxWidth: '200px', display: '-webkit-box', WebkitLineClamp: '3', WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {recentMessage && recentMessage.length > 0 && recentMessage.map((messageItem) => {
                                if (messageItem.userId === item.idParticipant) {
                                    return messageItem.message;
                                }
                                return null;
                                })}
                                </div>
                            </td>
                            <td style={{
                                color: selectedItems.includes(item) ? '#fff' : '#000', maxWidth: '35px', whiteSpace: "nowrap", overflow: 'hidden', fontWeight: '300', textOverflow: 'ellipsis'
                            }}>
                                {rolepackages[item.idParticipant] ? rolepackages[item.idParticipant].name : ""}
                            </td>

                            <td style={{ color: selectedItems.includes(item) ? '#fff' : '#000' }}>
                               
                                    <span
                                        style={{
                                            display: 'inline-block',
                                            width: '10px',
                                            height: '10px',
                                            borderRadius: '50%',
                                            backgroundColor: getCircleColor(item),
                                        }}
                                    ></span>
                            </td>
                          
                        </tr>
                    ))}
                </tbody>
                </Table>
            </div>
        </div>
    );
}

export default List;

