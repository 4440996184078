import React, { useState, useEffect } from "react";
import Messagebox from "./MessagesToUsers";
import WelcomeMessage from "./WelcomeModal";
import AuthenticationForm from "./Authenticationform";
import IdentificationMessage from "./WelcomeModalWithIdentification";

function ParticipantContainer() {
    const [modalShow, setModalShow] = useState(true);
    const [authenticated, setAuthenticated] = useState(false);
    const [isIdentificationNeeded, setIsIdentificationNeeded] = useState(false);
    const [showIdentificationModal, setShowIdentificationModal] = useState(false);

    useEffect(() => {
        checkPassword();
    }, []);



    //Used when the user refreshes the page
    const checkPassword = async () => {
        try {
            const isAuthenticated = localStorage.getItem("authenticated");
            if (!isAuthenticated) {
                return;
            }
            const queryParams = `?passcode=${encodeURIComponent(JSON.stringify(isAuthenticated))}`;
            const response = await fetch(`/authenticate${queryParams}`, {
                method: 'GET'
            });

            if (response.ok) {
                setAuthenticated(true);
                setModalShow(false);
            }
        }
        catch (error) {

        }
    };

    const authenticate = async (code) => {
        try {
            const queryParams = `?passcode=${encodeURIComponent(JSON.stringify(code))}`;
            const response = await fetch(`/authenticate${queryParams}`, {
                method: 'GET'
            });

            if (response.ok) {
                const identificationNeeded = await response.json();
                setAuthenticated(true);
                setIsIdentificationNeeded(identificationNeeded);

                if (identificationNeeded) {
                    setShowIdentificationModal(true);
                    localStorage.setItem("authenticated", code);
                }
                else {
                    setModalShow(true);
                    localStorage.setItem("authenticated", code);
                }
                              
            }
            else {
                throw new Error("Authentication failed");
            }
        } catch (error) {
            throw error; 
        }
    };

    return (
        <div>
            {!authenticated ? (
                <AuthenticationForm onAuthenticate={authenticate}  />
            ) : (
                <>
                    <h1>Role information</h1>
                        <Messagebox />
                        {isIdentificationNeeded ? (
                            <IdentificationMessage show={showIdentificationModal} onHide={() => setShowIdentificationModal(false)} />
                        ) : (
                            <WelcomeMessage show={modalShow} onHide={() => setModalShow(false)} />
                        )}
                </>
            )}
        </div>
    );
}

export default ParticipantContainer;

