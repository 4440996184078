import React, { useState, useEffect } from "react";
import * as signalR from "@microsoft/signalr";
import ListOfConnectedUsers from "./ListofConnectedUsers";
import MessageInput from "./MessageInput";
import RunChosenSimulation from "./RunChosenSimulation";
import TruncateParticipants from "./TruncateParticipants";
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Btn from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Authentication from './Authenticationform.js';
import IdentificationModal from "./IdentificationModal";



const ConnectedUsers = () => {
    const [connectedUsers, setConnectedUsers] = useState([]);
    const [groups, setGroups] = useState([{}]);
    const [connection, setConnection] = useState(null);
    const [message, setMessage] = useState('');
    const [isMessageRead, setIsMessageRead] = useState(false);
    const [buttonClicked, setButtonClicked] = useState(false);
    const [simName, setSimName] = useState("");
    const [selectedValueSim, setSelectedValueSim] = useState("");
    const [selectedValueScen, setSelectedValueScen] = useState("");
    const [selectedItems, setSelectedItems] = useState([]);
    const [itemStatus, setItemStatus] = useState({});
    const [simPassword, setSimPassword] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [hasRolepackage, setHasRolepackage] = useState(false);
    const [rolepackageData, setRolepackageData] = useState(null);
    const [assignedRolepackages, setAssignedRolePackages] = useState({});
    const [selectedRolepackage, setSelectedRolepackage] = useState(null);
    const [scenarios, setScenarios] = useState([]);
    const [scenariosNumber, setScenariosNumber] = useState(0);
    const [currentScenario, setCurrentScenario] = useState([]);
    const [nextEnvironment, setNextEnvironment] = useState(false);
    const [disableButtons, setDisableButtons] = useState(false);
    const [allParticipantsHavePackages, setAllParticipantsHavePackages] = useState(false);

    const [identificationUpdate, setIdentificationUpdate] = useState(null);

    //#region Handle connections
    useEffect(() => {
        const connection = new signalR.HubConnectionBuilder()
            .withUrl('/chatHub', {
                //skipNegotiation: true,
                //transport: signalR.HttpTransportType.WebSockets
                transport: signalR.HttpTransportType.LongPolling
            })
            .withAutomaticReconnect()
            .configureLogging(signalR.LogLevel.None) //Change to .Debug for detailed information
            .build();

        connection.start().then(() => {
            console.log("SignalR connected");
        });

        connection.on("groupsUpdated", (groupNames) => {
            setGroups(groupNames);
        });

        connection.on("UserConnected", () => {

            connection.invoke("AddAdmin")
                .catch((error) => {
                    console.error(error);
                });
        });

        connection.on("UserDisconnected", () => {
        });

        connection.on('MessageRead', (userId) => {
            setIsMessageRead(true);
            handleItemStatusChange(userId, 'green');

        });

        connection.on('ReceiveIdentificationUpdate', ({ userId, identification }) => {
            handleIdentificationUpdate(userId, identification);
        });

        setConnection(connection);

        return () => {
            connection.stop();
        };


    }, []);

    //#endregion

    const handleIdentificationUpdate = (userId, identification) => {
        setIdentificationUpdate({ userId, identification });
    };

    //#region Handle sending messages/roles
    const handleSend = async () => {
        if (connection && connection.state === signalR.HubConnectionState.Connected) {
            var conIds = handleExportSelectedItems();
            connection.invoke("SendMessageToParticipants", conIds, message);
            setMessage('');
            setSelectedItems([]);
            setButtonClicked(true);

            const Idparticipants = handleExportSelectedItems();
            const roleDescription = message;

            try {
                const queryParams = `?Idparticipants=${encodeURIComponent(JSON.stringify(Idparticipants))}&roleDescription=${encodeURIComponent(roleDescription)}`;
                const response = await fetch(`/fetch-participant-characters${queryParams}`, {
                    method: 'GET'
                });

                if (response.ok) {
                    console.log('Request succeeded');
                    conIds.forEach((conId) => {
                        handleItemStatusChange(conId, 'red');
                        setAlertStatus({
                            message: 'Test!',
                            variant: 'success'
                        })

                        showAlert('success', 'Message sent to participant(s)!');

                    });

                    setButtonClicked(false);
                } else {
                    console.log('Request failed');
                    showAlert('danger', 'Could not send message! Please make sure you have selected atleast one participant and have chosen a role OR written a message.');
                }

                setMessage('');
                setSelectedItems([]);

            } catch (error) {
                console.error(error);

            }

        } else {
            console.warn('Connection is not yet established or has been closed');
        }
    };

    const handleSendToAllRandomRoles = () => {
        if (connection && connection.state === signalR.HubConnectionState.Connected) {
            var conIds = handleExportSelectedItems();
            var sim = selectedValueSim;
            var scen = selectedValueScen;

            setButtonClicked(true);

            connection.invoke("SendRandomRolesToAllParticipants", sim, scen)
                .then((response) => {
                    const participantIds = JSON.parse(response);
                    participantIds.forEach((conId) => {
                        handleItemStatusChange(conId, 'red');
                        showAlert('success', 'Roles have been randomized to all participants!');
                    });
                    setButtonClicked(false);
                })
                .catch((error) => {
                    console.error('Error invoking SendRandomRolesToAllParticipants:', error);
                    showAlert('danger', 'Could not randomize roles! Please make sure you have selected an environment.');
                });
        } else {
            console.warn('Connection is not yet established or has been closed');
        }
    };

    // Handle sending rolepackages to selected participants
    const handleRolepackageSend = () => {
        if (connection && connection.state === signalR.HubConnectionState.Connected) {
            var conIds = handleExportSelectedItems();
            if (conIds.length == 0) {
                showAlert('danger', 'Please select at least one participant!');
                return;
            }
            setAssignedRolePackages((prevRolePackages) => ({
                ...prevRolePackages,
                ...conIds.reduce((obj, id) => ({ ...obj, [id]: selectedRolepackage }), {})
            }));
            showAlert('success', 'Rolepackage(s) assigned to participant(s)!');
        }
    }

    // Handle sending random rolepackages to all participants
    const handleSendToAllRandomRolepackages = () => {
        if (connection && connection.state === signalR.HubConnectionState.Connected) {
            var randomRolePackages = getRandomRolepackages(groups.length);
            randomRolePackages = randomRolePackages.flat();
            setAssignedRolePackages((prevRolePackages) => ({
                ...prevRolePackages,
                ...groups.reduce((obj, user, index) => {
                    const rolePackage = JSON.parse(JSON.stringify(randomRolePackages[index]));
                    return { ...obj, [user.idParticipant]: rolePackage };
                }, {})
            }));
            showAlert('success', 'Rolepackage(s) assigned to all participant(s)!');
        }
    }

    // This function generates a randomized list of role packages for each participant. 
    // It first shuffles the role packages, then ensures there are enough role packages for each participant by duplicating the shuffled list if necessary, 
    // and finally returns a list containing a role package for each participant.
    const getRandomRolepackages = (participantCount) => {
        const shuffledRolePackages = [...rolepackageData.rolepackage];
        for (let i = shuffledRolePackages.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledRolePackages[i], shuffledRolePackages[j]] = [shuffledRolePackages[j], shuffledRolePackages[i]];
        }

        while (shuffledRolePackages.length < participantCount) {
            shuffledRolePackages.push(...shuffledRolePackages.slice(0, participantCount - shuffledRolePackages.length));
        }

        return shuffledRolePackages.slice(0, participantCount);
    }


    // This function sends the next role from each assigned role package to the corresponding participant. 
    // It iterates over all assigned role packages, removes the first role from each package, and sends it to the participant.
    // It also updates the UI and saves the role to the database.
    const handleSendNextRole = () => {
        if (connection && connection.state === signalR.HubConnectionState.Connected) {
            setAssignedRolePackages((prevRolePackages) => {
                const nextRolePackages = { ...prevRolePackages };
                for (const conId in nextRolePackages) {
                    const rolePackage = nextRolePackages[conId];
                    if (rolePackage && rolePackage.rolepackageCharacters && rolePackage.rolepackageCharacters.length > 0) {
                        const role = rolePackage.rolepackageCharacters.shift();
                        connection.invoke("SendMessageToParticipants", [conId], role.description)
                            .then(async () => {
                                handleItemStatusChange(conId, 'red');
                                showAlert('success', 'Role sent to participant!');

                                const idparticipants = [conId];
                                const roleDescription = role.description;
                                const queryParams = `?Idparticipants=${encodeURIComponent(JSON.stringify(idparticipants))}&roleDescription=${encodeURIComponent(roleDescription)}`;
                                const response = await fetch(`/fetch-participant-characters${queryParams}`, {
                                    method: 'GET'
                                });

                                if (response.ok) {
                                    console.log('Role saved to database');
                                } else {
                                    console.log('Failed to save role to database');
                                }
                            })
                            .catch((error) => {
                                console.error(error);
                            });
                    }
                }
                return nextRolePackages;
            });
        }
    }
    //#endregion

    // This function checks if all participants have been assigned a role package.
    function participantsHaveRolepackages() {
        if (groups.length > 0 && groups.every((group) => assignedRolepackages[group.idParticipant]))
        {
            setAllParticipantsHavePackages(true);
            handleSendNextRole();
            changeScenarios();
            return;
        }
        showAlert('danger', 'Please assign rolepackages to all participants before sending roles!');
    }

    function handleExportSelectedItems() {

        const items = selectedItems.map((item) => (item.idParticipant));
        return (items)
    }

    function handleMarkMessageRead() {
        connection.invoke('MarkMessageRead', message, localStorage.getItem("UserNameRCR"));
    }

    const sendRoleMessage = (roleMessage) => {
        setMessage(roleMessage);
    };

    function getSimName(name) {
        setSimName(name);
    }

    function handleSimValueChange(value) {
        setSelectedValueSim(value);
    }

    function handleScenValueChange(value) {
        setSelectedValueScen(value);
    }

    const handleSelectedItemsChange = (items) => {
        setSelectedItems(items);
    };

    const setAllScenarios = (scenarios) => {
        setScenarios(scenarios);
        setCurrentScenario(scenarios[scenariosNumber]);
    }

    // This function changes the current scenario to the next scenario in the list.
    const changeScenarios = () => {

        setScenariosNumber(prevScenariosNumber => {
            let newScenariosNumber = prevScenariosNumber + 1;
            if (newScenariosNumber >= scenarios.length) {
                setNextEnvironment(false);
                setDisableButtons(false);
                newScenariosNumber = 0;
                setCurrentScenario(scenarios[newScenariosNumber]);
                setAllParticipantsHavePackages(false);
                return newScenariosNumber;
            }
            else {
                setDisableButtons(true);
                setNextEnvironment(true);
                setCurrentScenario(scenarios[newScenariosNumber]);
                return newScenariosNumber;
            }
        });
    }
          

    const handleItemStatusChange = (idParticipant, color) => {
        setItemStatus((prevItemStatus) => ({
            ...prevItemStatus,
            [idParticipant]: color,
        }));
    };


    const [alertStatus, setAlertStatus] = useState({
        variant: 'primary',
        message: 'This is a message',
    });

    const showAlert = (newVariant, newMessage) => {
        setAlertStatus({
            ...alertStatus,
            variant: newVariant,
            message: newMessage,
            status: true,
        });
        setTimeout(() => {
            setAlertStatus({
                ...alertStatus,
                status: false,
            });
        }, 7500);
    };

    useEffect(() => {
        getSimPasswordFromStart()
    }, []);

    function getSimPassword() {
        fetch('/fetch-simulation-password')
            .then(response => response.json())
            .then(simPassword => setSimPassword(simPassword));
        setShowModal(true);
    }

    function getSimPasswordFromStart() {
        fetch('/fetch-existing-simulation-password')
            .then(response => response.json())
            .then(simPassword => setSimPassword(simPassword));
    }


    const removeAllGroups = () => {
        connection.invoke("ResetServer")
            .then(() => {
                console.log("Server reset completed");
            })
            .catch(error => {
                console.error("Error resetting server:", error);
            });

        /*getSimPassword(simPassword);*/
    };


    // personnel authentication

    const [authenticated, setAuthenticated] = useState(null);

    useEffect(() => {
        checkPassword();
    }, []);

    //Used when the user refreshes the page
    const checkPassword = async () => {
        try {
            const storedData = localStorage.getItem("personnelCredentials");
                const isAuthenticated = JSON.parse(storedData);
                
            if (!isAuthenticated) {
                setAuthenticated(false);
                return;
            }
            const url = '/authenticate-personnel';
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(isAuthenticated)
            });

            if (response.ok) {
                setAuthenticated(true);
            }
        }
        catch (error) {
        }
    };



    const authenticate = async (userName, password) => {

        const url = '/authenticate-personnel';

        const postData = {
            Name: userName,
            Password: password
        };
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(postData)
            });

            if (response.ok) {
                setAuthenticated(true);
                localStorage.setItem("personnelCredentials", JSON.stringify(postData));

            }
            else {
                throw new Error("Authentication failed");
            }
        } catch (error) {
            throw error;
        }
    };



    return (

        <div>
            {authenticated === null ? (
                <div></div>
              
                //<div className="d-flex justify-content-center align-items-center vh-100" style={{ fontWeight:'1000', fontSize:'30px' } }>Loading...</div>

            ) : !authenticated ? (
                <Authentication onAuthenticate={authenticate} />
            ) : (
                    

            <Container>
               

                <Row>
                    
                        <Col>
                            <h1 className="running-sim-txt" style={{ margin: "2% 0 1% ", fontWeight: '100', color: '#EBBAA0' }}><span>Running simulation:</span> {simName}</h1>
                        </Col>
                    </Row>
                    <Row>
                                        <Col>
                                            <h3 style={{ margin: "0 0 2% ", fontSize: '18px', fontWeight: '100', color: '#EBBAA0' }}>
                                                <span>Participant password:</span> {simPassword && simPassword !== 0 ? simPassword : 'Password not generated'}</h3>
                            <Btn onClick={getSimPassword} style={{margin: "0 0 2% 0"} }>Generate participant password</Btn>
                                        </Col>
                                        <IdentificationModal show={showModal} onHide={() => setShowModal(false)} />
                            </Row>
                    <Row>
                    <Col style={{ margin: "0 5%" }}>
                        <h2 className="header-txt" style={{ margin: "0 0 5%" }}>Connected participants</h2>
                        <ListOfConnectedUsers
                                        items={groups}
                                        selectedItems={selectedItems}
                                        onSelectedItemsChange={handleSelectedItemsChange}
                                        itemStatus={itemStatus}
                                        buttonClicked={buttonClicked}
                                        rolepackages={assignedRolepackages}
                                        identificationUpdate={identificationUpdate}
                        />

                    </Col>
                    <Col>
                                        <h2 className="header-txt" style={{ margin: "0 0 5%" }}>Send messages</h2>
                                    {hasRolepackage ? (<div>
                                        <Row>
                                            <Col>     <RunChosenSimulation
                                                sendRoleMessage={sendRoleMessage}
                                                handleSimValueChange={handleSimValueChange}
                                                handleScenValueChange={handleScenValueChange}
                                                buttonClicked={buttonClicked}
                                                getSimName={getSimName}
                                                setHasRolepackage={setHasRolepackage}
                                                setRolepackageData={setRolepackageData}
                                                setSelectedRolepackage={setSelectedRolepackage}
                                                setScenarios={setAllScenarios}

                                            /> </Col>
                                        </Row>
                                        <Row style={{ marginTop: '20px' }}>
                                            {alertStatus.status && (
                                                <Alert show={true} variant={alertStatus.variant}>
                                                    {alertStatus.message}
                                                </Alert>
                                            )}
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Btn style={{ marginBottom: '20px', marginTop: '20px' }} disabled={disableButtons} onClick={handleRolepackageSend}>Send to selected participants</Btn>
                                            </Col>
                                            <Col>
                                                <Btn style={{ marginBottom: '20px', marginTop: '20px' }} disabled={disableButtons} onClick={handleSendToAllRandomRolepackages}>Randomize rolepackages of chosen simulation to all participants</Btn>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Btn onClick={() => {
                                                    if (allParticipantsHavePackages) {
                                                        handleSendNextRole();
                                                        changeScenarios();
                                                    } else {
                                                        participantsHaveRolepackages();
                                                    }
                                                }}>
                                                    {nextEnvironment ? "Next environment: " : "Start environment: "} {currentScenario.name}
                                                </Btn>

                                            </Col>
                                        </Row>
                                    </div>
                                    ) : (
                                        <div>
                                            <Row>
                                                <Col>     <RunChosenSimulation
                                                        sendRoleMessage={sendRoleMessage}
                                                        handleSimValueChange={handleSimValueChange}
                                                        handleScenValueChange={handleScenValueChange}
                                                        buttonClicked={buttonClicked}
                                                        getSimName={getSimName}
                                                        setHasRolepackage={setHasRolepackage}
                                                        setRolepackageData={setRolepackageData}
                                                        setSelectedRolepackage={setSelectedRolepackage}
                                                        setScenarios={setAllScenarios}

                                                /> </Col>
                                            </Row>
                                            <Row>
                                                <Col><MessageInput message={message} setMessage={setMessage} /></Col>
                                            </Row>
                                            <Row>
                                                {alertStatus.status && (
                                                    <Alert show={true} variant={alertStatus.variant}>
                                                        {alertStatus.message}
                                                    </Alert>
                                                )}
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Btn style={{ marginBottom: '20px' }} onClick={handleSend}>Send to selected participants</Btn>
                                                </Col>
                                                <Col>
                                                    <Btn onClick={handleSendToAllRandomRoles}>Randomize roles of chosen environment to all participants</Btn>
                                                </Col>
                                                </Row>
                                                
                                        </div>)}             
                                </Col>
                    </Row>
                    <Row className="truncate">
                        <Col>
                            <TruncateParticipants onTruncante={removeAllGroups} />
                        </Col>
                    </Row>             
            </Container>
            )}
        </div>
    );
};

export default ConnectedUsers;







