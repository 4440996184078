import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Step3({ onSubmit, onSaveItemsThree, simulation, roles, addRole, prevStep, addPackage, scenarios, onSavedRolepackages }) {
    const [scenarioName, setScenarioName] = useState(scenarios[0].name);
    let [scenarioCount, setScenarioCount] = useState(0);
    const [formData, setFormData] = useState([{ description: '' }]);
    const [listData, setListData] = useState([]);
    const [formDataRolepackage, setFormDataRolepackage] = useState([{ name: '' }]);
    const [listDataRolepackage, setListDataRolepackage] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [newRolepackage, setNewRolepackage] = useState([]);
    const [savedSelectedItems, setSavedSelectedItems] = useState([]);
    const [isButtonClicked, setIsButtonClicked] = useState(true);
    const [count, setCount] = useState(0);
    const [openStates, setOpenStates] = useState(savedSelectedItems.map(() => false));
    const [message, setMessage] = useState("Add role to environment:");
    const [showScenarioName, setShowScenarioName] = useState(true);
    const [isRoleDisabled, setIsRoleDisabled] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");


    useEffect(() => {
        if (Array.isArray(roles)) {
            setFormData(roles);
            setListData(roles);
        }
    }, []);

    const toggle = (index) => {
        const newOpenStates = [...openStates];
        newOpenStates[index] = !newOpenStates[index];
        setOpenStates(newOpenStates);
    };

    //Updates scenario name and count
    function changeScenarioName() {
        setScenarioCount(scenarioCount += 1);

        if (scenarioCount === scenarios.length) {
            setMessage("All environments have roles!");
            setShowScenarioName(false); 
            setIsRoleDisabled(true);
            setShowErrorMessage(false);
        } else {
            setShowScenarioName(true); 
            setScenarioName(scenarios[scenarioCount].name);
        }
        
    }

    function handleSubmit(event) {
        event.preventDefault();
        if (listData.length === 0) {
            alert("Please add at least one role to the simulation.");
        }
        else {
            onSubmit(listData);
            setListData(formData);
        }
    }

    function handleInputChange(event, index) {
        const { name, value } = event.target;
        const updatedFormData = [...formData];
        updatedFormData[index] = {
            ...updatedFormData[index],
            [name]: value
        };
        setFormData(updatedFormData);
    }

    // This function updates the value of a specific field in the formDataRolepackage state when the input changes.
    function handleInputChangeRolepackage(event, index) {
        const { name, value } = event.target;
        const updatedFormData = [...formDataRolepackage];
        updatedFormData[index] = {
            ...updatedFormData[index],
            [name]: value
        };
        setFormDataRolepackage(updatedFormData);
    }

    function handleAdd() {
        setFormData([...formData, { description: '' }]);

        const newArray = formData;
        addRole(newArray);
        setListData(formData);
    }

    // This function adds a new role package, resets the scenario count and name, and prepares the UI for the next role package addition.
    function handleAddRolepackage() {
        setFormDataRolepackage([...formDataRolepackage, { name: '' }]);
        setIsButtonClicked(false);
        const newArray = formDataRolepackage;
        addPackage(newArray);
        setListDataRolepackage(formDataRolepackage);
        setShowErrorMessage(false);
        setIsRoleDisabled(false);
        setScenarioCount(0);
        setScenarioName(scenarios[0].name);
        setSelectedRoles([]);
    }

    const handleRoleClick = (role) => {
        setSelectedRoles(role);
        addRoleToRolepackage(role);
    };

    // This function validates the scenario count to see if all scenarios have roles, 
    // updates the state to reflect the saved role package, and resets the UI for the next role package addition.
    const handleSaveClick = () => {
        if (scenarioCount !== scenarios.length) {
            setErrorMessage("You have to choose roles for each environment");
            setShowErrorMessage(true);
        }
        else { 
        setSavedSelectedItems([...savedSelectedItems, newRolepackage]);
        setIsButtonClicked(true);
        setListDataRolepackage([]);
        setFormDataRolepackage([{ name: '' }]);
        setSelectedRoles([]);
        setNewRolepackage([]);
        setCount(0);
        setScenarioCount(0);
        setScenarioName(scenarios[0].name);
        setShowScenarioName(true);
        setMessage("Add role to environment:");
        setIsRoleDisabled(false);
    }
    };

    // This function adds a selected role to the current role package, updates the role package state, 
    // and increments the scenario count. If no role package is defined, it sets an error message.
    function addRoleToRolepackage(role) {
        let index = 0;
        let updatedRolepackages;

        if (formDataRolepackage[0].name === '') {
            setIsRoleDisabled(true);
            setListDataRolepackage([]);
            setFormDataRolepackage([{ name: '' }]);
            setErrorMessage("You have to add a rolepackage first");
            setShowErrorMessage(true);
            setIsButtonClicked(true);
        } else {

            if (newRolepackage[index] && newRolepackage[index].RolepackageCharacters) {
                updatedRolepackages = [...newRolepackage];
            }
            else {
                updatedRolepackages = [...newRolepackage];
                updatedRolepackages[index] = { ...formDataRolepackage[index], RolepackageCharacters: [] };
            }

            if (updatedRolepackages[index].RolepackageCharacters.length >= scenarios.length) {
                return;
            }

            updatedRolepackages[index].RolepackageCharacters.push(role);
            setNewRolepackage(updatedRolepackages);
            setCount(updatedRolepackages[index].RolepackageCharacters.length);
            changeScenarioName();
        }
    }

    // This function handles the submission of role packages. It checks if any role packages have been added, 
    // alerts the user if none are found, and otherwise prepares the role packages for saving.
    const handleSubmitRolepackage = (event) => {
        event.preventDefault();
        if (savedSelectedItems.length === 0) {
            alert("Please add at least one rolepackage to the simulation.");
        }
        else {
            const newSavedItem = {
                name: simulation.name,
                scenarios: scenarios,
                rolepackage: savedSelectedItems,
            };
            onSaveItemsThree(newSavedItem);
            onSavedRolepackages(savedSelectedItems);
        }
    };

    const contentStyle = {
        display: 'block',
        width: 'auto',
        boxSizing: 'border-box',
        float: 'left',
        margin: '0 16px'

    }

    const removeItem = (index) => {
        if (listData.length > 0) {
            setFormData(formData.filter((o, i) => index !== i));
            setListData(listData.filter((o, i) => index !== i));
        }
    };

    // Removes a role package from the list of saved role packages.
    const removeRolepackage = (index) => {
        if (savedSelectedItems.length > 0) {
            setSavedSelectedItems(savedSelectedItems.filter((o, i) => index !== i));
        }
    };

    // Removes a role package from the list of role packages being created.
    const removeRolepackageItem = (index) => {
        if (listDataRolepackage.length > 0) {
            setFormDataRolepackage(formDataRolepackage.filter((o, i) => index !== i));
            setListDataRolepackage(listDataRolepackage.filter((o, i) => index !== i));
            setNewRolepackage(newRolepackage.filter((o, i) => index !== i));
            setShowScenarioName(true);
            setMessage("Add role to environment:");
            setScenarioCount(0);
            setIsButtonClicked(true);
            setSelectedRoles(null);
            setCount(0);
            setScenarioName(scenarios[0].name);
            setIsRoleDisabled(false);
        }
    };

    return (
        <div>

            <Row>
                <h1 style={{ textAlign: 'center', marginBottom: '40px' }}>You are creating the simulation: {simulation.name}</h1>

            </Row>
            <div style={{ border: "1.5px solid #fff", borderRadius: "6px" }}>

                <ul className="steps">
                    <li>1: Name</li>
                    <li>2: Environments</li>
                    <li className="is-active">3: Roles</li>
                    <li>4: Connect roles to environments</li>
                    <li>5: Save simulation</li>
                </ul>
                <Row style={{ margin: "auto", display: "flex", justifyContent: "center" }}>
                    <form onSubmit={handleSubmit} style={contentStyle} className="inputRoles">
                        {formData.length > 0 && (
                            <div>
                                <label>
                                    Add roles/messages:<br></br>
                                    <textarea className="role-txt-area" style={{ height: "100px", borderRadius: '5px' }} type="text" name="description" value={formData[formData.length - 1].description} onChange={(event) => handleInputChange(event, formData.length - 1)} />
                                </label>
                            </div>
                        )}
                        <Button type="button" style={{ marginLeft: 'auto', marginRight: 'auto' }} onClick={handleAdd}>+</Button>
                    </form>
                </Row>

                <Row>
                    <div style={{ maxHeight: "240px", overflowY: "auto", marginTop: '35px' }}>
                        <p style={{ fontWeight: 'bold' }}>Added roles ({listData.length}):</p>
                        <ul style={{ wordWrap: "break-word", listStyle: 'none' }}>
                            {listData.map((data, index) => (
                                <li key={index}>{data.description}  {index !== listData.length && (<span className="role-delete" onClick={() => removeItem(index)}> X</span>)}<hr />
                                </li>

                            ))}
                        </ul>
                    </div>
                </Row>
                <Row style={{ marginTop: '30px', padding: "20px" }}>

                    <Col className="text-start">
                        <Button onClick={prevStep} >{' <'} Previous step</Button>
                    </Col>
                    <Col className="text-end">
                        <form onSubmit={handleSubmit}>
                            <Button type="submit">Next step{' >'}</Button>
                        </form>
                    </Col>

                </Row>

                <Row style={{ margin: "auto", justifyContent: "center" }}>
                    <form style={contentStyle} className="inputRoles">
                        {formDataRolepackage.length > 0 && (
                            <div>
                                <label>
                                    Add rolepackages:<br></br>
                                    <textarea className="role-txt-area" style={{ height: "100px", borderRadius: '5px' }} type="text" name="name" value={formDataRolepackage[formDataRolepackage.length - 1].name} onChange={(event) => handleInputChangeRolepackage(event, formDataRolepackage.length - 1)} />
                                </label>
                            </div>
                        )}
                        {isButtonClicked && (<Button type="button" style={{ marginLeft: 'auto', marginRight: 'auto' }} onClick={handleAddRolepackage}>+</Button>) }
                        
                    </form>
                </Row>

                <Row style={{ margin: "20px", display: "flex", justifyContent: "center" }}>
                    {(<div className="number-count">
                        <Container style={{ padding: "20px 0" }}>
                            <Row >
                                <Col xs={4}>
                                    <h4>Current Rolepackage:</h4>

                                    {listDataRolepackage.map((data, index) => (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {index !== data.length && (
                                                <div
                                                    key={index}
                                                    style={{
                                                        padding: "10px",
                                                        backgroundColor: "white",
                                                        borderRadius: '6px',
                                                        cursor: "auto",
                                                        margin: "10px",
                                                        width: "100%",
                                                        whiteSpace: "nowrap",
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'
                                                    }}
                                                >
                                                    {data.name} ({count})
                                                </div>
                                            )}
                                            {index !== listDataRolepackage.length && (
                                                <span className="role-delete" onClick={() => removeRolepackageItem(index)}> X</span>
                                            )}
                                        </div>
                                    ))}
                                </Col>

                                

                                <Col style={{ maxWidth: '65%' }} >

                                    <div style={{ maxHeight: "540px", overflowY: "auto" }}>
                                        <h4>
                                            {message} {showScenarioName ? <span style={{ color: '#D2691E' }}>{scenarioName}</span> : ""}
                                        </h4>
                                        {listData.map((data, index) => (
                                            index !== data.length && (<div
                                                key={index}
                                                style={{
                                                    padding: "10px",
                                                    borderRadius: '6px',
                                                    width: "94%",
                                                    backgroundColor: data === selectedRoles ? "#8fbc8f" : "#f9f9f9",
                                                    cursor: "pointer",
                                                    whiteSpace: "nowrap",
                                                    overflow: 'hidden',
                                                    margin: "10px",
                                                    textOverflow: 'ellipsis',
                                                    pointerEvents: isRoleDisabled ? 'none' : 'auto', 
                                                    opacity: isRoleDisabled ? 0.6 : 1 
                                                }}
                                                onClick={() => {
                                                    if (!isRoleDisabled) { 
                                                        handleRoleClick(data);
                                                    }
                                                }}
                                            >
                                                {data.description}
                                            </div>
)
                                        ))}
                                    </div>

                                </Col>

                            </Row>
                        </Container>

                    </div>)}

                    {(<div >
                        <h4>{showErrorMessage ? errorMessage : ""}</h4>
                        <Button style={{
                        pointerEvents: showErrorMessage ? 'none' : 'auto',
                        opacity: showErrorMessage ? 0.6 : 1
                        }}onClick={() => handleSaveClick() }>Save rolepackage</Button>

                     </div>)
                }

                </Row>

                <Row style={{ margin: "20px", display: "flex", justifyContent: "center" }}>

                    {(<div className="role-scenario" style={{ maxHeight: "600px", overflowY: "auto", marginTop: '60px', textAlign: "center"}}>
                        <h4>Overview of added rolepackages:</h4>
                        <ul style={{ wordWrap: "break-word", listStyle: 'none' }}>

                                        {savedSelectedItems.map((packages, index) => (
                                            packages.map((item, subindex) => (
                                                <li className="scenario-overview" key={subindex}>
                                                    Rolepackage {index + 1} : {item.name} <span onClick={() => toggle(index)}> {openStates[index] ? "-" : "+"} </span> {index !== savedSelectedItems.length && (<span className="role-delete" onClick={() => removeRolepackage(index)}> x</span>)}

                                                    {openStates[index] && (<ul>
                                                        {item.RolepackageCharacters.map((character, i) => (
                                                            <li style={{ marginBottom: "5px", listStyle: 'none', fontSize: '16px', marginRight: "35px", marginTop: "10px" }} className="toggle" key={i}>
                                                                {character.description} <hr></hr>
                                                            </li>
                                                        ))}
                                                    </ul>)}
                                                </li>
                                            ))
                                        ))}
                        </ul>

                    </div>)}

                </Row>

                <Row style={{ marginTop: '30px', padding: "20px" }}>

                    <Col className="text-start">
                        <Button onClick={prevStep} >{' <'} Previous step</Button>
                    </Col>
                    <Col className="text-end">
                        <form onSubmit={handleSubmitRolepackage}>
                            <Button type="submit">Next step{' >'}</Button>
                        </form>
                    </Col>

                </Row>

            </div>

        </div>

    );
}

export default Step3;
