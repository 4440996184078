import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";


const WelcomeModalWithIdentification = (props) => {
    const [inputValue, setInputValue] = useState("");


    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };


    const handleClose = () => {
        if (inputValue !== "") {
            props.onHide();
                
            fetch('/identification', {
                method: 'POST',
                body: JSON.stringify({ id: localStorage.getItem("UserNameRCR"), identification: inputValue }),
                headers: { 'Content-Type': 'application/json' },

            })
                .then(response => response.json())
                .then(data => {
                    console.log(data.message);
                    data.logs.forEach(log => console.log(log));
                })
                .catch(error => console.error('Error:', error));


        }
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false }
            style={{
                color: "black",
            }}
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    RCR Simulation lab
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Welcome!</h4>
                <p><br></br>
                    You will be assigned new roles during the simulation,<br></br>
                    and information about your role will show on this page.<br></br><br></br>
                    When you get a new message please confirm that you have read it by clicking the "I have read this message" - button.<br></br><br></br>
                    For this simulation you are required to fill in the assigned identification below:
                </p>
                <input type="text" value={inputValue} onChange={handleInputChange} placeholder="Identification..." style={{ border: "2px solid black"  }} />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleClose}>Submit</Button>
            </Modal.Footer>
        </Modal>
    );
};
export default WelcomeModalWithIdentification;