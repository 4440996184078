import React, { useState } from "react";
import SaveSimMessage from "../SaveSimModal";
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function Step5({ rolePackage, simulation, onSubmit, prevStep }) {
    
    const [modalShow, setModalShow] = useState(false);
    const [openStates, setOpenStates] = useState(rolePackage.map(() => false));

    const toggle = (index) => {
        const newOpenStates = [...openStates];
        newOpenStates[index] = !newOpenStates[index];
        setOpenStates(newOpenStates);
    };
    
    const handleSubmit = () => {
        setModalShow(true);
    };

    return (
            <div>

                <Row>
                    <h1 style={{ marginBottom: '40px' }}>You are creating the simulation: {simulation.name} </h1></Row>
                <div style={{ border: "1.5px solid #fff", borderRadius: "6px", padding: "15px 25px 20px 20px " }}>
                    <Row>

                        <ul className="steps" style={{ marginBottom: '45px' }}>
                            <li>1: Name</li>
                            <li>2: Environment</li>
                            <li>3: Roles</li>
                            <li>4: Connect roles to environments</li>
                            <li className="is-active">5: Save simulation</li>
                        </ul>
                    </Row>
<Row>

                    {(<div className="role-scenario" style={{ maxHeight: "600px", overflowY: "auto", marginTop: '60px', textAlign: "center" }}>
                    <h4>Overview of the new simulation {simulation.name}:</h4>
                    <ul style={{ wordWrap: "break-word", listStyle: 'none' }}>
                            {rolePackage.map((packages, index) => (
                                packages.map((item, subindex) => (
                                    <li className="scenario-overview" key={subindex}>
                                        Rolepackage {index + 1} : {item.name} <span onClick={() => toggle(index)}> {openStates[index] ? "-" : "+"}</span>

                                        {openStates[index] && (<ul>
                                            {item.RolepackageCharacters.map((character, i) => (
                                                <li style={{ marginBottom: "5px", listStyle: 'none', fontSize: '16px', marginRight: "35px", marginTop: "10px" }} className="toggle" key={i}>
                                                    {character.description} <hr></hr>
                                                </li>
                                            ))}
                                        </ul>)}
                                    </li>
                                ))
                            ))}
                    </ul>



                </div>)}

            </Row>
            <Row style={{ marginTop: '30px' }}>

                <Col className="text-start">
                    <Button onClick={prevStep} >{' <'} Previous step</Button>
                </Col>
                    <Col className="text-end">
                        <Button 
                            onClick={handleSubmit}>Save simulation</Button>
                    </Col>
            </Row>

            <SaveSimMessage
                show={modalShow}
                onHide={() => setModalShow(false)}
                onSave={onSubmit}
               
            />

            </div >
            </div>

                    );
}

export default Step5;
