import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";

const IdentificationModal = ({ show, onHide, ...props }) => {



    const handleYes = () => {
        fetch('/updateIdentificationState', {
            method: 'POST',
            body: JSON.stringify(true),
            headers: { 'Content-Type': 'application/json' },

        }).then(() => {
            onHide(); 
        });
    };

    function handleNo(){
        fetch('/updateIdentificationState', {
            method: 'POST',
            body: JSON.stringify(false),
            headers: { 'Content-Type': 'application/json' },

        }).then(() => {
            onHide();
        });
    }




    return (
        <Modal
            show={show}
            onHide={handleNo}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{
                color: "black",
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Do you want to ask the participants for identification for this simulation? 
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ textAlign: 'center' }}>
                <p>
                    <Button onClick={handleNo} style={{ marginRight: "15px", backgroundColor: "#DC3545"}}>No</Button>
                    <Button onClick={handleYes}>Yes, I want to ask the participants for identification</Button>                    
                </p>

            </Modal.Body>

        </Modal>
    );
};

export default IdentificationModal;